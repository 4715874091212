import React from 'react';

import './Trust.css';


const Trust = () => {
    return (
        <div className="backgroundff">
            <h1 className='titer'>يثق بنا الكثير</h1>

            <nav className='nav2'>
                <h1 className="nav-item1"> ما قاله الآباء والأمهات </h1>
                <h1 className="nav-item1">ما قاله المعلمون </h1>
            </nav>

            <div className='messages'>
                <div className='msgL'>
                <div className='trust'></div>
                <div className='trust'></div>
                
                </div>

                <div className='msgR'>
                <div className='trust'></div>
                <div className='trust'></div>
                
                </div>
               
                
            </div>






        </div>










       

    );
};

export default Trust;
