import React from 'react';
import phone from '../../../assets/images/phone.png';
import appel from '../../../assets/images/appel.png';
import web from '../../../assets/images/web.png';
import store from '../../../assets/images/store.png';
import './Download.css';

const Download = () => {
    return (
        <div className="download">
            <img src={phone} className='phone' />

            <div className='alll-para'>
                <h1 className="stepp">: حمل التطبيق الآن </h1>
                <p className="paraa">
                    وفر لطفلك الرّفيق المثاليّ لتعزيز
                    <br></br>
                    تجربته التّعليميّة بطريقة ممتعة ومشوّقة
                </p>
                
                <div className='iconsss'>
                    <a href="#"><img href="#" src={store} className='store' /></a>
                    <a href="#"><img href="#" src={appel} className='appel' /></a>
                    <a href="#"><img href="#" src={web} className='web' /></a>
                </div>



            </div>


        </div>
    );
};

export default Download;
