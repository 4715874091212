import React from 'react';
import './Footer.css';
import nova from '../../../assets/images/dmnova.png';
import logo from '../../../assets/images/logo.png';
import facebook from '../../../assets/images/FACEBOOK3.png';
import linkedin from '../../../assets/images/LINKEDIN3.png';
import insta from '../../../assets/images/INSTAGRAM3.png';


const Footer = () => {
    return (
        <div>
            <nav className='footer1'>

                <button className='social'>
                    <img href="#" src={linkedin} alt="Logo" className="footer-logo" />
                    <img href="#" src={insta} alt="Logo" className="footer-logo" />
                    <img href="#" src={facebook} alt="Logo" className="footer-logo" />
                </button>



                <ul className="footer-items">

                    <a href="#" className="footer-item" > يثق بنا الكثير ◀︎  </a>
                    <a href="#" className="footer-item">  تواصل معنا ◀︎ </a>
                    <a href="#" className="footer-item">  مميزات التطبيق ◀︎   </a>
                </ul>

                <ul className="footer-items">
                    <a href="#" className="footer-item"> ... لكل إنجاز خطوة </a>
                    <a href="#" className="footer-item">من نحن ؟ ◀︎ </a>
                    <a href="#" className="footer-item">المحتويات التعليمية ◀︎  </a>

                </ul>

                <div className="khot">
                    <button>
                        <img href="#" src={logo} alt="Logo" className="navbar-logo" />
                    </button>
                </div>


            </nav>

            <div className="footer2">
                <img src={nova}></img>: المنتج المنفذ
            </div>
        </div>




















    )
};

export default Footer;

