import React from 'react';
import avatar from '../../../assets/images/avatar.png';
import appel from '../../../assets/images/appel.png';
import web from '../../../assets/images/web.png';
import store from '../../../assets/images/store.png';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <img src={avatar} className='avatar' />
      <div className='all-para'>
        <h1 className="step">  ...لكل إنجاز خطوة</h1>
        <h2 className="we">من  نحن ؟</h2>
        <p className="para">
          تطبيق خُطوة هو الرّفيق المثاليّ لأطفال المرحلة الابتدائيّة الّذين
          <br></br>
          .يرغبون في تعزيز تجربتهم التّعليميّة بطريقة ممتعة ومشوّقة
          <br></br>
          تطبيق أُعدّ من قبل خبراء بيداغوجيين في ضوء البرامج المدرسيّة
           <br></br>
           .وأفضل الطّرق والمداخل التربويّة المعاصرة
        </p>
        <h2 className="jouer">  : العب الآن </h2>
        <div className='icons'>
          <a href="#"><img href="#" src={store} className='store' /></a>
          <a href="#"><img href="#" src={appel} className='appel' /></a>
          <a href="#"><img href="#" src={web} className='web' /></a>
        </div>



      </div>


    </div>
  );
};

export default About;
