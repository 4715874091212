import React from 'react';
import frame from '../../../assets/images/Frame.png';
import frame2 from '../../../assets/images/Frame2.png';
import pyramide from '../../../assets/images/pyramide.png';
import parents from '../../../assets/images/parents.png';
import './Best.css';


const Best = () => {
    return (
        
            <div className='all'>

                <div className='sec1'>
                    <img src={frame} className='frame' />
                    <div className='pa'>
                    <h1 className='mmapp'>مميزات التطبيق</h1>
                        <h1 className='ttapp'>محتوى متوافق مع المناهج الدراسيّة</h1>
                        <text className="parag">
                            في تطبيق "خُطوة"، نحرص على تطابق جميع الموادّ الدراسيّة المُقدّمة مع البرامج
                            الرسميّة للمرحلة الابتدائية وحين تتعدّاها فمن أجل إسنادها وإغنائها والتوسّع في
                            <br></br>
                            .مضامينها
                            <br></br>
                            فريقنا البيداغوجي المحترف والتدقيق المتعدّد المستويات ضمانتا الإعداد الجيّد للأنشطة التعليميّة والألعاب التربويّة التي تستند إلى المناهج والمقاربات الرسميّة

                        </text>
                        </div>
                </div>



                <div className='sec2'>

                    <div className='pa'>

                        <h1 className='ttapp'> تذليل صعوبات التعلم </h1>
                        <p className="parag">

                            تطبيق "خُطوة" يتميّز بالقدرة على الكشف على صعوبات التعلّم لدى بعض الأطفال، ممّا
                            <br></br>
                            يُمكّن الآباء والمعلّمين من تقديم الدعم المناسب والملائم للأطفال عبر أنشطة خضعت لتكييفات خصوصيّة
                            <br></br>
                            نحن نعمل على تطوير تطبيق "خُطوة" ليكون رفيقًا موثوقًا للأطفال في رحلة تعليميّة فريدة ومُمتعة، حيث يمكن للأطفال توظيف أقصى إمكاناتهم و تجاوز صعوباتهم التعليميّة وتحقيق أفضل النتائج
                        </p>
                    </div>
                    <img src={frame2} className='frame2' />
                </div>

                <div className='sec3'>
                    <img src={pyramide} className='pyramide' />
                    <div className='pa'>

                        <h1 className='ttapp'>هرم بلوم للأهداف التعليمية</h1>

                        <p className="parag">
                            من خلال الواقع المُعزّز، يستمتع الأطفال بتفاعل ثلاثيّ الأبعاد مع الكائنات والموادّ والشّخصيات الافتراضيّة المُرتبطة بالموضوعات التّعليميّة، ممّا يُعزّز تجربتهم ويعمّق معرفتهم بالمحتويات التي بُنيت بشكل متدرّج يستند إلى هرم "بلوم" إذ
                            <br></br>
                            يستكشف المتعلّم المعرفة ويخزّنها بذاكرته ويستحضرها
                            <br></br>
                            يمارس أنشطة تضمن فهْم تلك المعرفة فهما مُكتملا
                            <br></br>
                            يطبّق المكتسب ضمن وضعيات معيشة ويوظّفها توظيفا متدرّجا.
                            <br></br>
                            يحلّل المعارف والمهارات المكتسبة ويعيد تركيبها لاستدماجها ضمن منظومته.
                            <br></br>
                            يقيّم المكتسب، يسند له قيمة ويطلق عليه حكما موضوعيّا.
                            <br></br>
                            يبتكر ويبدع منتجا يستند إلى المكتسب محتوى وتمشّيات.
                        </p>
                    </div>
                </div>

                <div className='sec4'>

                    <div className='pa'>

                        <h1 className='ttapp'>ما يقدمه تطبيق خطوة للوالدين </h1>
                        <p className="parag">
                            نحن في تطبيق "خطوة" نهتم بتقديم الدعم والتوجيه للوالدين لمساعدتهم في رحلة تعليم أطفالهم. نقدم لكم ميزة التقارير المتعلقة بصعوبات التعلم التي قد يواجهها الأطفال، وذلك لمساعدتكم في فهم احتياجاتهم وتقديم الدعم المناسب لهم.
                            بالإضافة إلى ذلك، نقدم تقارير عن إنجازات الطفل والتمارين التي أنجزها في التطبيق. يمكنكم متابعة تطوراتهم وتقييم تقدمهم في رحلتهم التعليمية
                            <br></br>
                            كما نوفر لكم برامج تطويرية تساعدكم في دعم تطور وتنمية مهارات الأطفال بشكل شامل
                            <br></br>
                            "نحن نضمن لكم الأمان الكامل عند استخدام تطبيق "خطوة

                        </p>
                    </div>
                    <img src={parents} className='parents' />
                </div>








            </div>




        
    );
};

export default Best;
