import React from 'react';
import facebook from '../../../assets/images/FACEBOOK3.png';
import linkedin from '../../../assets/images/LINKEDIN3.png';
import insta from '../../../assets/images/INSTAGRAM3.png';


import './Contact.css';


const Contact = () => {
    return (
        <div className='nna'>
            <h1 className='titerr'>تواصل معنا</h1>

            <div className='rectangles'>

                <div className='rec3'>
                    <div className='box1'>
                        <h3 className='p1' >وسائل التواصل الإجتماعي</h3>
                        <p className='socials'>
                            <img href="#" src={linkedin} alt="Logo" className="contact-logo" />
                            <img href="#" src={insta} alt="Logo" className="contact-logo" />
                            <img href="#" src={facebook} alt="Logo" className="contact-logo" />
                        </p>
                       
                    </div>


                </div>
                <div className='rec2'>
                    <div className='box1'>
                        <h3 className='p1' >عنوان البريد الالكترونى  </h3>
                        <p className='number'>service.client@khotwa.com</p>
                    </div>


                </div>
                <div className='rec1'>
                    <div className='box1'>
                        <h3 className='p1' > خدمة الحرفاء </h3>
                        <p className='number'>+216 56 568 066</p>
                    </div>


                </div>





            </div>


        </div>
    );
};

export default Contact;
