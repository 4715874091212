import React from 'react';
import Navbar from '../../layout/Navbar/Navbar';
import Footer from '../../layout/Footer/Footer';


import './Home.css';
import About from '../../common/About/About';
import Contenu from '../../common/Contenu/Contenu';
import Best from '../../common/Best/Best';
import Trust from '../../common/Trust/Trust';
import Contact from '../../common/Contact/Contact';
import Download from '../../common/Download/Download';


const Home = () => {
  return (
    <div>
      <div className='background'>
        <div className='topHome' >
        <Navbar />
        <About />
        </div>
<div className='green'></div>
      </div>
      <Contenu />
      <div className='backgroundOO'>
        <Best />
        <Trust />
        <Contact />
        <Download/>
        <Footer/>
      </div>
    </div>






  );
};

export default Home;
