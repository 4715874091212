import React from 'react';
import logo from '../../../assets/images/logo.png';
import parents from '../../../assets/images/parent.png';

import './Navbar.css';

const Navbar = () => {
  return (
    <nav className="navbar">

      


      <div className="navbar-left">
        <button className="button-parent">
          <img href="#" src={parents} alt="parent"></img>
        </button>
      </div>

      <ul className="navbar-items">
        <a href="#" className="navbar-item">تواصل معنا</a>
        <a href="#" className="navbar-item">يثق بنا الكثير</a>
        <a href="#" className="navbar-item">مميزات التطبيق</a>
        <a href="#" className="navbar-item">المحتويات التعليمية</a>
      </ul>

      <div className="navbar-right">
        <button>
          <img href="#" src={logo} alt="Logo" className="navbar-logo" />
        </button>

      </div>
    </nav>
  );
};

export default Navbar;
