import './App.css';

import Home from './components/pages/Home/Home';
 
function App() {
  return (
  <div>
<Home />
  </div>
  
    
)}

export default App;
