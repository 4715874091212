import React from 'react';
import img1 from '../../../assets/images/img1.png';
import img2 from '../../../assets/images/img2.png';
import img3 from '../../../assets/images/img3.png';
import img4 from '../../../assets/images/img4.png';
import img5 from '../../../assets/images/img5.png';
import img6 from '../../../assets/images/img6.png';
import './Contenu.css';

const Contenu = () => {
    return (
        <div>



            <nav className='nav'><p className="nav-item"> المحتويات التعليمية</p></nav>
            <div className='background2'>
                <div className='background3'>

              

                

                    <div className='iconss'>
                        

                            <div className='icons1'>

                                <div className='text1'>
                                    <img src={img6} />
                                    <p >علوم</p>
                                </div>

                                <div className='text1' >
                                    <img src={img5} />
                                    <p >الواقع المعزز</p>
                                </div>

                                <div className='text1'>
                                    <img src={img4} />
                                    <p >ألعاب التعليمية</p>
                                </div>

                            </div>



                            <div className='icons2'>
                                <div className='text1'>
                                    <img src={img3} />
                                    <p>فيديوهات وقصص مفيدة</p>
                                </div>
                                <div className='text1'>
                                    <img src={img2} />
                                    <p>محتوى تعليمي تفاعلي</p>
                                </div>
                                <div className='text1'>
                                    <img src={img1} />
                                    <p>فنون التلوين</p>
                                </div>



                            </div>

                        </div>
                    

                </div>

                </div>



            

        </div>

    );
};

export default Contenu;

//je veux chaque image a un titre on bottom
<div className='texts'>
    <div className='text1'>
        <p>علوم</p>
        <p>الواقع المعزز</p>
        <p>ألعاب التعليمية</p>
    </div>

    <div className='text2'>
        <p>فيديوهات وقصص مفيدة</p>
        <p>محتوى تعليمي تفاعلي</p>
        <p>فنون التلوين</p>
    </div>
</div>
